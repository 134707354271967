
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="firstname">Nome</label>
          <input type="text" class="form-control" id="firstname" placeholder="Nome" v-model="user.firstname" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-6">
          <label for="lastname">Sobrenome</label>
          <input type="text" class="form-control" id="lastname" placeholder="Nome" v-model="user.lastname" autofocus="autofocus" required="required">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="email">E-mail</label>
          <input type="text" class="form-control" id="email" placeholder="Título" v-model="user.email" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-4">
          <label for="role">Nível</label>
          <select class="form-control" v-model="user.role">
            <option value="user">Usuário</option>
            <option value="admin">Administrador</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" id="inactive" type="checkbox" v-model="user.inactive">
          <label class="form-check-label" for="inactive">Inativo</label>
        </div>
      </div>
      <div style="float: right;">
        <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
        <router-link class="btn btn-default" style="margin-left:10px;" to="/users">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  props: {
    save: Function,
    showPassword: Boolean,
  },
  data() {
    return {
      user: {},
      accessProfiles: [],
      validationErrors: [],
    };
  },
  mounted() {
    this.fetchGetAssociate();
    // this.fetchGetAccessProfiles();
  },
  computed: {
    showpassword() {
      return true;
    },
  },
  methods: {
    async fetchGetAssociate() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`users/${this.$route.params.id}`);
      this.user = res.data.user;
      this.$root.loadingVisible = false;
    },
    // async fetchGetAccessProfiles() {
    //   const res = await ApiAxios.get('/v0/accessProfiles');
    //   this.accessProfiles = res.data.accessProfiles;
    // },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      
      this.save( this.user );
    },
    validate() {
      const errors = [];
      // const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!pattern.test(this.user.email)) errors.push("E-mail inválido");

      if (!this.user.firstname) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
